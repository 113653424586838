import React, { useEffect } from 'react'
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu
} from 'react-pro-sidebar'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MainMenuItem from './MenuItem'
import GoSubMenu from './SubMenu'
import SubMenuItem from './SubmenuItem'
import { logoutUser } from '../../../actions/authActions'
import { getProfile } from '../../../actions/dashboardActions'
import './styles.scss'
import owner from '../../../images/owner.png'
import walkers from '../../../images/walkers.png'
import microInsurance from '../../../images/life-insurance.svg'
import fundCrisic from '../../../images/crisisFund.svg'
import packages from '../../../images/packages.svg'
import AppMatrics from '../../../images/appMatrics.svg'
import booking from '../../../images/booking.png'
import Fees from '../../../images/fees.svg'
import file from '../../../images/static.png'
import profile from '../../../images/profile.png'
import settings from '../../../images/settings.png'
import password from '../../../images/password.png'
import notification from '../../../images/notification.png'
import privacy_policy from '../../../images/privacy_policy.png'
import terms from '../../../images/terms.png'
import sidebar_left from '../../../images/sidebar_left.png'
import sidebar_right from '../../../images/sidebar_right.png'

const Sidebar = (props) => {
  useEffect(() => {
    const token = localStorage.getItem('jwtToken')
    props.getProfile(token)
  }, [])
  const userRole = props.accessRole
  return (
    <>
      <ProSidebar
        collapsed={props.collapsed}
        toggled={props.toggled}
        breakPoint='md'
        onToggle={props.handleToggle}
      >
        <SidebarHeader>
          {window.innerWidth > 1025 &&
            !props.collapsed &&
            (!props.toggled || props.toggled) && (
              <h3>
                <img className='logo' src='/logo.png' alt='logo' />
              </h3>
            )}
          {window.innerWidth > 1025 && props.collapsed && (
            <h3>
              <img className='logo' src='/logo_sm.png' alt='logo' />
            </h3>
          )}
          {window.innerWidth <= '1025' && props.toggled && (
            <>
              <h3>
                <img className='logo' src='/logo.png' alt='logo' />
              </h3>

              <i
                onClick={() => props.handleToggle('toggled')}
                className='fa fa-times'
              />
            </>
          )}
        </SidebarHeader>

        <SidebarContent>
          {/* Dashboard */}

          <Menu iconShape='square' popperArrow>
            {/* Manage Owner */}

            <MainMenuItem
              icon={owner}
              collapsed={props.collapsed}
              name='Owners'
              link='/admin/owners'
              handleClick={() => props.handleToggle('toggled')}
            />

            {/* Walker */}
            <MainMenuItem
              icon={walkers}
              collapsed={props.collapsed}
              name='Service Provider'
              link='/admin/walkers'
              handleClick={() => props.handleToggle('toggled')}
            />

            <MainMenuItem
              icon={Fees}
              collapsed={props.collapsed}
              name='Convenience Fee'
              link='/admin/convinience-fee'
              handleClick={() => props.handleToggle('toggled')}
            />

            <MainMenuItem
              icon={packages}
              collapsed={props.collapsed}
              name='Package'
              link='/admin/walkers-package'
              handleClick={() => props.handleToggle('toggled')}
            />

            <MainMenuItem
              icon={fundCrisic}
              collapsed={props.collapsed}
              name='Crisis Fund'
              link='/admin/crisis-fund'
              handleClick={() => props.handleToggle('toggled')}
            />

            <MainMenuItem
              icon={microInsurance}
              collapsed={props.collapsed}
              name='Micro Insurance'
              link='/admin/micro-insurance'
              handleClick={() => props.handleToggle('toggled')}
            />

            {/* <MainMenuItem
              icon={AppMatrics}
              collapsed={props.collapsed}
              name='App Metrics'
              link='/admin/app-matrics'
              handleClick={() => props.handleToggle('toggled')}
            /> */}

            {/* Bookings */}
            {userRole === '0' || userRole === '1' ?
              <MainMenuItem
                icon={booking}
                collapsed={props.collapsed}
                name='Bookings'
                link='/admin/bookings'
                handleClick={() => props.handleToggle('toggled')}
              />
              : null}
            {userRole === '0' || userRole === '1' ?
              <MainMenuItem
                icon={booking}
                collapsed={props.collapsed}
                name='Commission'
                link='/admin/commission'
                handleClick={() => props.handleToggle('toggled')}
              /> :
              null}
            {userRole === '0' || userRole === '1' ?
              <MainMenuItem
                icon={booking}
                collapsed={props.collapsed}
                name='Deleted Users'
                link='/admin/deleted/user'
                handleClick={() => props.handleToggle('toggled')}
              /> :
              null}
            {userRole === '0' || userRole === '1' ?
              <GoSubMenu collapsed={props.collapsed} name='File' icon={file}>
                <SubMenuItem
                  icon={privacy_policy}
                  name='Privacy Policy'
                  link='/admin/privacy-policy'
                  collapsed={props.collapsed}
                />
                <SubMenuItem
                  icon={terms}
                  name='Owner T & C'
                  link='/admin/terms-and-condition'
                  collapsed={props.collapsed}
                />
                <SubMenuItem
                  icon={terms}
                  name='Service Provider T & C'
                  link='/admin/walker/terms-and-condition'
                  collapsed={props.collapsed}
                />
              </GoSubMenu>
              : null}

            {/* Profile */}

            <MainMenuItem
              icon={profile}
              collapsed={props.collapsed}
              name='Profile'
              link='/admin/profile'
              handleClick={() => props.handleToggle('toggled')}
            />

            {/* Settings */}
            <GoSubMenu collapsed={props.collapsed} name='Settings' icon={settings}>
              <MainMenuItem
                icon={password}
                collapsed={props.collapsed}
                name='Password'
                link='/admin/password'
                handleClick={() => props.handleToggle('toggled')}
              />
              {userRole === '0' ?
                <MainMenuItem
                  icon={notification}
                  collapsed={props.collapsed}
                  name='Notification'
                  link='/admin/notification'
                  handleClick={() => props.handleToggle('toggled')}
                />
                : null}
            </GoSubMenu>

          </Menu>
        </SidebarContent>
        <div className='sidebar_footer'>
          <button onClick={() => props.handleToggle('collapsed')}>
            <img
              src={props.collapsed ? sidebar_right : sidebar_left}
              alt='toggle_image'
            />
          </button>
        </div>
      </ProSidebar>
    </>
  )
}

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole,
})

export default connect(mapStateToProps, { logoutUser, getProfile })(
  withRouter(Sidebar)
)
